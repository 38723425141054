import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RestApiService {
    token: string;
    tenant: string;

    constructor(private http: HttpClient) { }

    get<T>(path: string, params?: any) {
        let options = this.getHttpOptions(params);
        return this.http.get<T>(this.getUri(path), options);
    }

    post<T>(path: string, data: any) {
        let options = this.getHttpOptions();
        return this.http.post<T>(this.getUri(path), data, options);
    }

    put<T>(path: string, data: any) {
        let options = this.getHttpOptions();
        return this.http.put<T>(this.getUri(path), data, options);
    }

    delete<T>(path: string, params?: any) {
        let options = this.getHttpOptions();
        return this.http.delete<T>(this.getUri(path), options);
    }

    getUri(path: string): string {
        return `${environment.apiEndPoint}${path}`;
    }

    configToken(token: string): RestApiService {
        this.token = token;
        return this;
    }

    configTenant(tenant: string): RestApiService {
        this.tenant = tenant;
        return this;
    }

    private getHttpOptions(params?: HttpParams) {
        let options: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'tenant': this.tenant
            })
        };
        if (this.token) {
            options.headers = options.headers.set('Authorization', this.token);
        }
        if (params) options.params = this.getParams(params);
        return options;
    }

    private getParams(params: any) : HttpParams {
        if (!params) return null;

        let httpParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                httpParams = httpParams.set(key, params[key]);
            }
        }
        return httpParams;
    }
}
