import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../rest-api/rest-api.service';

@Injectable()
export class AuthService {
    token: string;
    tenant: string = 'pna'; // later should read from cookies
    userInfo: any;

    constructor(private rest: RestApiService) {
        rest.configTenant(this.tenant);
        this.rest = rest;
        this.token = localStorage.getItem('token');
    }

    login(username: string, password: string): Observable<{}> {
        return new Observable<{}>(observer => {
            return this.rest.post(`ew-auth/login`, {
                userName: username,
                pwd: password
            }).subscribe(
                (result: any) => {
                    this.token = result.token;
                    this.rest.configToken(this.token);
                    localStorage.setItem('token', this.token);

                    observer.next(this.token);
                    observer.complete();
                },
                err => {
                    this.token = null;
                    observer.error(err);
                }
            );
        });
    }

    resetPassword(siteUrl: string, email: string): Observable<{}> {
        return this.rest.post('ew-auth/reset-password', { link: siteUrl, email: email });
    }

    resetPasswordFromLink(linkId: string, password: string) {
        return this.rest.post('ew-auth/reset-password-from-link', { loginLinkId: linkId, pwd: password });
    }

    getResetPasswordLink(id: string) {
        return this.rest.get(`ew-auth/reset-password-link/${id}`);
    }

    logout() {
        this.token = null;
        this.userInfo = null;
        localStorage.removeItem('token');
        this.rest.configToken(this.token);
    }

    getToken() {
        return this.token;
    }

    getUser() {
        if (!this.userInfo) {
            this.userInfo = this.parseJwt(this.token);
        }
        return this.userInfo;
    }

    isAuthenticated(): boolean {
        if (!this.token) return false;

        const tokenInfo = this.parseJwt(this.token);
        if (tokenInfo.exp < Date.now() / 1000) {
            return false;
        }

        this.rest.configToken(this.token);
        return true;
    }

    hasRole(role: string): boolean {
        const user = this.getUser();
        return user && user.roles.indexOf(role) > -1;
    }

    hasAnyRole(roles: string[]): boolean {
        const user = this.getUser();
        return roles.filter(r => user && user.roles.indexOf(r) > -1).length > 0;
    }

    canUpdateServiceItem() {
        return this.getUser().userName === 'admin' 
        || this.hasAnyRole([
            'svc_administration_all',
            'svc_administration_manufacturing_update'
        ]);
    }

    canAddServiceItem() {
        return this.getUser().userName === 'admin' 
        || this.hasAnyRole([
            'svc_administration_all',
            'svc_administration_manufacturing_add'
        ]);
    }

    canAccessAdministration() {
        return this.canAccessManufacturingAdministration() 
            || this.canAccessUserAdministration()
            || this.canAccessWarrantyAdministration();
    }

    canAccessManufacturingAdministration() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_manufacturing'
            ])
        );
    }

    canAccessPaymentInfoAdministration() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_payment_info'
            ])
        );
    }

    canAccessDealerPaymentStatusAdministration() {
        if (this.getUser().organizationId) {
            return this.hasAnyRole([
                'dealer'
            ]);
        }

        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_warranty_registrations_pay_status'
            ])
        );
    }

    canAccessWarrantyAdministration() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_warranty'
            ])
        );
    }

    canAccessUserAdministration() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_user'
            ])
        );
    }

    canAccessOrganizationAdministration() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_organizations_view',
                'svc_administration_user'
            ])
        );
    }

    canPay() {
        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_warranty_registrations_pay'
            ])
        );
    }

    canViewWarrantyRegistrations() {
        if (this.getUser().organizationId) {
            return this.hasAnyRole([
                'dealer'
            ]);
        }

        return !this.getUser().organizationId && (
            this.getUser().userName === 'admin' 
            || this.hasAnyRole([
                'svc_administration_all',
                'svc_administration_warranty',
                'svc_warranty_registrations'
            ])
        );
    }

    isASP() {
        return !!this.getUser().organizationId;
    }

    getOrganizationId() {
        return this.getUser().organizationId;
    }

    private parseJwt(token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        // let base64Url = token.split('.')[1];
        // let base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(jsonPayload);
    }
}
