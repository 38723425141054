import { Injectable } from '@angular/core';
import { RestApiService } from '../rest-api/rest-api.service';
import { Observable, noop } from 'rxjs';
import 'rxjs/Rx';
import { CacheService } from './cache.service';
import { AuthService } from '../auth/auth.service';

declare const $: any;

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    uuid () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    print (html: string) {
        let $print = $('#print-iframe');
        if (!$print.length) {
            $print = $('<iframe id="print-iframe" name="print-iframe"></iframe>');
            $('body').append($print);
        }
        var $body = $('body', $print.get(0).contentWindow.document);
        $body.html(html);

        window.frames['print-iframe'].focus();
        window.frames['print-iframe'].print();
    }
    
    download (url: string) {
        let $download = $('#download-iframe');
        if (!$download.length) {
            $download = $('<iframe id="download-iframe" name="download-iframe"></iframe>');
            $('body').append($download);
        }
        $download.attr('src', url);
    }
}