import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { Router, ActivatedRouteSnapshot, ActivatedRoute, RouterEvent, RoutesRecognized, UrlSegment } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    isOpening = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private modalService: NgbModal) {

        router.events.subscribe((event: RouterEvent) => {
            if (event instanceof RoutesRecognized) {
                this.updatePopupState(event.state.root.children);
            }
        });
    }

    updatePopupState(routeSnapshots: ActivatedRouteSnapshot[]) {
        let route = this.getPopupOutletActivatedRoute(routeSnapshots);
        if (route) {
            const navigation = this.router.getCurrentNavigation();
            if (navigation && navigation.extras && navigation.extras.state && navigation.extras.state.closePopup) {
                setTimeout(() => {
                    this.close();
                }, 100)
            }
            else {
                this.isOpening = true;
                this.open(route.data.popupSize || 'small');
            }
        }
        else {
            this.isOpening = false;
            setTimeout(() => {
                this.close();
            }, 100)
        }
    }

    private emitOpenSource = new Subject<any>();
    openEmitted$ = this.emitOpenSource.asObservable();
    open(mode?: any) {
        this.emitOpenSource.next(mode);
    }

    private emitCloseSource = new Subject<any>();
    closeEmitted$ = this.emitCloseSource.asObservable();
    close(path?: any[]) {
        this.emitCloseSource.next();
        if (path) {
            this.router.navigate(path.concat([{ outlets: { popupOutlet: null } }]));
        }
        else if (this.isOpening) {
            let modulePath = this.getModulePath(this.router.url);
            if (modulePath.length) {
                this.router.navigate([].concat(modulePath).concat([{ outlets: { popupOutlet: null } }]))
            }
        }
    }

    clearOutlet(force?: boolean) {
        if (this.isOpening || force) {
            let modulePath = this.getModulePath(this.router.url);
            this.router.navigate([].concat(modulePath).concat([{ outlets: { popupOutlet: null } }]))
        }
    }

    private emitChangeSource = new Subject<any>();
    changeEmitted$ = this.emitChangeSource.asObservable();
    change() {
        this.emitChangeSource.next();
    }

    private emitForceRefreshSource = new Subject<any>();
    forceRefreshSource$ = this.emitForceRefreshSource.asObservable();
    forceRefresh() {
        this.emitForceRefreshSource.next();
    }

    showSuccess(message?: string, title?: string) {
        this.toastr.success(message || "Dữ liệu đã được lưu thành công", title || "Thông tin");
    }

    showInfo(message: string, title?: string) {
        this.toastr.info(message, title || "Thông tin");
    }

    showWarning(message: string, title?: string) {
        this.toastr.warning(message, title || "Cảnh báo");
    }

    showError(message?: string, title?: string) {
        this.toastr.error(message || "Đã có lỗi xảy ra", title || "Lỗi");
    }

    confirm(message: string, title?: string, okText?: string, closeText?: string) {
        const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
        modalRef.componentInstance.message = message;
        if (title) modalRef.componentInstance.title = title;
        if (okText) modalRef.componentInstance.okText = okText;
        if (closeText) modalRef.componentInstance.closeText = closeText;

        return new Observable<Boolean>(observer => {
            modalRef.result
                .then(() => {
                    observer.next(true);
                }, () => {
                    observer.next(false);
                })
                .then(() => {
                    observer.complete();
                });
        });
    }

    showDialog(component, data) {
        const modalRef = this.modalService.open(component, { centered: true });
        modalRef.componentInstance.data = data;
        return new Observable<Boolean>(observer => {
            modalRef.result
                .then(() => {
                    observer.next(true);
                }, () => {
                    observer.next(false);
                })
                .then(() => {
                    observer.complete();
                });
        });
    }

    getPopupOutletActivatedRoute(routes: ActivatedRouteSnapshot[]) {
        for (let i = 0; i < routes.length; i++) {
            let route = routes[i];
            if (route.outlet === 'popupOutlet') {
                return route;
            }

            let activatedRoute = this.getPopupOutletActivatedRoute(route.children);
            if (activatedRoute) {
                return activatedRoute;
            }
        }
        return null;
    }

    getModulePath(url: string): string[] {
        let segments = url.split('/');
        let results = [];
        for (let i = 0; i < segments.length; i++) {
            if (segments[i].indexOf("(") >= 0) break;
            if (!segments[i]) continue;
            results.push(segments[i]);
        }

        return results;
    }
}