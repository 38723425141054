import { Component, OnInit, ElementRef, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-notification-sidebar',
    templateUrl: './notification-sidebar.component.html',
    styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {

    openSub: Subscription;
    closeSub: Subscription;
    isOpen = false;

    @ViewChild('sidebar') sidebar: ElementRef;

    constructor(private router: Router, 
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private layoutService: LayoutService) {

        this.openSub = layoutService.openEmitted$.subscribe(
            value => {
                this.onOpen(value);
            });
        this.closeSub = layoutService.closeEmitted$.subscribe(
            value => {
                this.onClose();
            });
    }

    ngOnInit() {
        this.layoutService.updatePopupState(this.router.routerState.snapshot.root.children);
    }

    ngOnDestroy() {
        if (this.openSub) {
            this.openSub.unsubscribe();
        }
    }

    onOpen(mode: string) {
        this.renderer.addClass(this.sidebar.nativeElement, 'open');
        this.renderer.addClass(this.sidebar.nativeElement, mode || 'normal');
        this.isOpen = true;
    }

    onClose() {
        this.reset();
        this.isOpen = false;
        this.layoutService.forceRefresh();
        this.layoutService.clearOutlet();
    }

    reset() {
        this.renderer.removeClass(this.sidebar.nativeElement, 'open');
        this.renderer.removeClass(this.sidebar.nativeElement, 'small');
        this.renderer.removeClass(this.sidebar.nativeElement, 'normal');
    }
}
