
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { NgbModule, NgbDateParserFormatter, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastrModule } from "ngx-toastr";
import { FileUploadModule } from 'ng2-file-upload';
import { CustomFormsModule } from 'ng2-validation';

import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';

// the second parameter 'fr' is optional
registerLocaleData(localeVi, 'vi-vn');

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard, WarrantyAdministrationGuard, ManufacturingAdministrationGuard, UserAdministrationGuard, PaymentInfoAdministrationGuard, DealerPaymentStatusAdministrationGuard, OrganizationAdministrationGuard } from './shared/auth/auth-guard.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbDateCustomParserFormatter } from './shared/formatters/NgbDateCustomParserFormatter';
import { HttpErrorHandler } from './shared/auth/http-error-handler';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        NgbModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        FileUploadModule,
        CustomFormsModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        WarrantyAdministrationGuard,
        ManufacturingAdministrationGuard,
        UserAdministrationGuard,
        OrganizationAdministrationGuard,
        PaymentInfoAdministrationGuard,
        DealerPaymentStatusAdministrationGuard,
        { provide: LOCALE_ID, useValue: 'vi-vn' },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandler, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
