import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.isAuthenticated();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class ManufacturingAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canAccessManufacturingAdministration();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class WarrantyAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canViewWarrantyRegistrations();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class UserAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canAccessUserAdministration();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class OrganizationAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canAccessOrganizationAdministration();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}


@Injectable()
export class PaymentInfoAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canAccessPaymentInfoAdministration();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}

@Injectable()
export class DealerPaymentStatusAdministrationGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.authService.canAccessDealerPaymentStatusAdministration();
        if (!isAuthenticated) {
            // navigate to login page
            this.router.navigate(['/auth']);
        }
        return isAuthenticated;
    }
}
