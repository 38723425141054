import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpErrorHandler implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).do(evt => {
            if (evt instanceof HttpResponse) {
                if (evt.status === 401) {
                    location.reload();
                }
                // console.log('---> status:', evt.status);
                // console.log('---> filter:', req.params.get('filter'));
            }
        });
    }
}