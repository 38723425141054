import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FULL_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

import { AuthGuard } from './shared/auth/auth-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/public/look-up',
        pathMatch: 'full',
    },
    { path: '', component: FullLayoutComponent, data: { title: 'full Views', preload: true }, children: FULL_ROUTES, canActivate: [AuthGuard] },
    { path: '', component: ContentLayoutComponent, data: { title: 'content Views', preload: true }, children: CONTENT_ROUTES }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {
        preloadingStrategy: PreloadAllModules
      }), HttpClientModule],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
