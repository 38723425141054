import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config.service';
import { AppRoutesService } from './app-routes.service'
import { WarrantyCounterService } from '../services/warranty-counter.service';

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit {

    @ViewChild('toggleIcon') toggleIcon: ElementRef;
    public menuItems: any[];
    depth: number;
    activeTitle: string;
    activeTitles: string[] = [];
    expanded: boolean;
    nav_collapsed_open = false;
    logoUrl = 'assets/img/logo.png';
    public config: any = {};

    constructor(
        private renderer: Renderer2,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private configService: ConfigService,
        private appRoutesService: AppRoutesService,
        public warrantyCounterSvc: WarrantyCounterService
    ) {
        if (this.depth === undefined) {
            this.depth = 0;
            this.expanded = true;
        }
    }

    ngOnInit() {
        this.config = this.configService.templateConf;
        this.menuItems = this.appRoutesService.getRoutes();

        if (this.config.layout.sidebar.backgroundColor === 'white') {
            this.logoUrl = 'assets/img/logo-dark.png';
        }
        else {
            this.logoUrl = 'assets/img/logo.png';
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.config.layout.sidebar.collapsed != undefined) {
                if (this.config.layout.sidebar.collapsed === true) {
                    this.expanded = false;
                    this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                    this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                    this.nav_collapsed_open = true;
                }
                else if (this.config.layout.sidebar.collapsed === false) {
                    this.expanded = true;
                    this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                    this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                    this.nav_collapsed_open = false;
                }
            }
        }, 0);
    }

    toggleSlideInOut() {
        this.expanded = !this.expanded;
    }

    handleToggle(titles) {
        this.activeTitles = titles;
    }

    ngxWizardFunction(path: string) {
        if (path.indexOf("forms/ngx") !== -1)
            this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
    }
}
