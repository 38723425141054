import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { IGridDataService, GridSearchResult, GridExportResult, GridSearchQuery } from '../services/IGridDataService';
import { SearchEvent, GridLoadedEvent } from './search-event.model';
import { UtilsService } from '../services/utils.service';

@Component({
    selector: 'sws-grid',
    templateUrl: './sws-grid.component.html',
    styleUrls: ['./sws-grid.component.scss']
})
export class SwsGridComponent implements OnInit {
    @Input() rowId = 'id';
    @Input() columns = [];
    @Input() headerHeight = 50;
    @Input() rowHeight = 36;
    @Input() isLoading = false;
    @Input() pageLimit = 30;
    @Input() selectionType = 'single';
    @Input() search = '';
    @Input() viewFilter = {};
    @Input() filter = {};
    @Input() project = '';
    @Input() sort = {};
    @Input() sorts = [];
    @Input() service: IGridDataService;

    @Output() select = new EventEmitter<any>();
    @Output() loaded = new EventEmitter<GridLoadedEvent>();

    data = [];
    hasMoreData = true;
    total = 0;
    dataLength = 0;
    lastOffsetY: number = 0;
    selected = [];

    constructor(private el: ElementRef, private utils: UtilsService) { }

    ngOnInit() {
        setTimeout(() => {
            this.onScroll(0);
        }, 0);
    }

    onScroll(offsetY: number) {
        if (offsetY === undefined) return;

        this.lastOffsetY = offsetY;
        // total height of all rows in the viewport
        const viewHeight =
            this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

        // check if we scrolled to the end of the viewport
        if (
            !this.isLoading && this.hasMoreData &&
            offsetY + viewHeight >= this.data.length * this.rowHeight
        ) {
            // total number of results to load
            let limit = this.pageLimit;

            // check if we haven't fetched any results yet
            if (this.data.length === 0) {
                // calculate the number of rows that fit within viewport
                const pageSize = Math.ceil(viewHeight / this.rowHeight);

                // change the limit to pageSize such that we fill the first page entirely
                // (otherwise, we won't be able to scroll past it)
                limit = Math.max(pageSize, this.pageLimit);
            }
            this.loadData(limit);
        }
    }

    onSort($event) {
        this.buildSort($event.sorts);
        this.reload();
    }

    onSelect({ selected }) {
        this.selected = selected;
        this.select.emit(this.selectionType === 'single' ? selected[0] : selected);
    }

    onActivate($event) {
    }

    getCurrentQuery() {
        return {
            includeTotal: true,
            filter: Object.assign({}, this.viewFilter, this.filter),
            select: this.project,
            search: this.search,
            skip: 0,
            take: 0,
            sort: this.sort
        };
    }

    loadData(limit: number, reload: boolean = false) {
        if (this.isLoading) return;
        if (!this.service) return;
        if (reload) limit = Math.max(this.data.length, this.pageLimit);
        const hasSort = this.sort && Object.keys(this.sort).length > 0 && this.sort.constructor === Object;
        if (!hasSort) this.buildSort(this.sorts);

        this.isLoading = true;
        let query = {
            includeTotal: true,
            filter: Object.assign({}, this.viewFilter, this.filter),
            select: this.project,
            search: this.search,
            skip: reload ? 0 : this.data.length,
            take: limit,
            sort: this.sort
        };
        this.service.search(query).subscribe((result: GridSearchResult) => {
            if (reload) {
                this.data = result.data;
                this.scrollTop();
            }
            else {
                this.data = this.data.concat(result.data);
            }
            
            this.total = result.total;
            this.dataLength = this.data ? this.data.length : 0;
            this.hasMoreData = result.data.length === limit;
            this.setSelected(result.data);

            this.loaded.emit({
                query: query,
                total: result.total
            })
        }, err => {
            console.log(err);
        }).add(() => this.isLoading = false);
    }

    exportData() {
        const hasSort = this.sort && Object.keys(this.sort).length > 0 && this.sort.constructor === Object;
        if (!hasSort) this.buildSort(this.sorts);

        this.isLoading = true;
        this.service.export({
            includeTotal: true,
            filter: Object.assign({}, this.viewFilter, this.filter),
            select: this.project,
            search: this.search,
            skip: 0,
            take: 0,
            sort: this.sort
        }).subscribe((result: GridExportResult) => {
            this.utils.download(result.uri);
        }, err => {
            console.log(err);
        }).add(() => this.isLoading = false);
    }

    setSelected(data) {
        if (!this.selected) return;
        const rowIds = this.selected.map(e => e[this.rowId]);
        let selected = [];
        data.forEach(element => {
            if (~rowIds.indexOf(element[this.rowId])) {
                selected.push(element);
            }
        });
        this.selected = this.selected.concat(selected);
    }

    buildSort(sorts: any[]) {
        this.sort = {};
        sorts.forEach(sort => {
            this.sort[sort.prop] = sort.dir === 'desc' ? -1 : 1;
        });
    }

    reload() {
        this.hasMoreData = true;
        this.data = [];
        this.total = 0;
        setTimeout(() => {
            this.onScroll(0);
        });
    }

    refresh() {
        this.hasMoreData = true;
        this.total = 0;
        this.loadData(0, true);
    }

    load(query: SearchEvent) {
        this.search = query.search;
        this.filter = query.filter;
        this.reload();
        this.scrollTop();
    }

    scrollTop() {
        let el = this.el.nativeElement.querySelector( '.datatable-body' );
        setTimeout(() => {
            el.scrollTop = 10; 
            el.scrollLeft = 10;
        }, 100);
        setTimeout(() => {
            el.scrollTop = 1; 
            el.scrollLeft = 1;
        }, 200);
        setTimeout(() => {
            el.scrollTop = 0; 
            el.scrollLeft = 0;
        }, 300);
    }
}
