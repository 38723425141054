import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { RouteInfo } from './sidebar.metadata';
import { inProgressStatuses, pendingStatuses } from '../data/serviceStatuses';

@Injectable({
    providedIn: 'root'
})
export class AppRoutesService {

    constructor(private authSvc: AuthService) { }

    getRoutes(): RouteInfo[] {
        const routes = [
            {
                path: '', visible: true, title: 'Bảo hành', icon: 'fa fa-wrench', class: 'has-sub', isExternalLink: false,
                submenu: [
                    {
                        path: '/warranty/registrations-by-dealer', visible: this.authSvc.canViewWarrantyRegistrations(), title: 'Đại lý KHBH', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/not-paid', visible: this.authSvc.canAccessDealerPaymentStatusAdministration(), title: 'Chưa thanh toán', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/paid', visible: this.authSvc.canAccessDealerPaymentStatusAdministration(), title: 'Đã thanh toán', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/return', visible: this.authSvc.canAccessDealerPaymentStatusAdministration(), title: 'Hàng trả lại', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/registrations', visible: this.authSvc.canViewWarrantyRegistrations(), title: 'Danh sách KHBH', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/warranty/look-up', visible: true, title: 'Tra cứu', icon: '', class: '', isExternalLink: false, submenu: []
                    }
                ]
            },
            {
                path: '', visible: this.authSvc.canAccessAdministration(), title: 'Quản trị', icon: 'fa fa-cogs', class: 'has-sub', isExternalLink: false,
                submenu: [
                    {
                        path: '/administration/manage-product-groups', visible: this.authSvc.canAccessManufacturingAdministration(), title: 'Quản lý sản phẩm', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-models', visible: this.authSvc.canAccessManufacturingAdministration(), title: 'Quản lý Model', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-service-items', visible: this.authSvc.canAccessWarrantyAdministration(), title: 'Quản lý số máy', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-commission-groups', visible: this.authSvc.canAccessPaymentInfoAdministration(), title: 'Quản lý nhóm đại lý', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-commission-group-models', visible: this.authSvc.canAccessPaymentInfoAdministration(), title: 'Quản lý hoa hồng', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-organizations', visible: this.authSvc.canAccessOrganizationAdministration(), title: 'Quản lý đại lý', icon: '', class: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/administration/manage-users', visible: this.authSvc.canAccessUserAdministration(), title: 'Quản lý người dùng', icon: '', class: '', isExternalLink: false, submenu: []
                    }
                ]
            }
        ];

        const menuItems = routes.filter(m => m.visible);
        menuItems.forEach(menuItem => {
            menuItem.submenu = menuItem.submenu.filter(m => m.visible);
        });

        return menuItems;
    }
}
