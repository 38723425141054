import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
    @Input() closeText: string = 'Không';
    @Input() okText: string = 'Đồng ý';
    @Input() title: string = 'Xác nhận';
    @Input() message: string = '';

    constructor(public modal: NgbActiveModal) { }

    ngOnInit() {
    }

}
