import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer
export const FULL_ROUTES: Routes = [
    {
        path: 'administration',
        loadChildren: './administration/administration.module#AdministrationModule',
        data: { preload: true }
    },
    {
        path: 'my',
        loadChildren: './my/my.module#MyModule',
        data: { preload: true }
    },
    {
        path: 'warranty',
        loadChildren: './warranty/warranty.module#WarrantyModule',
        data: { preload: true }
    }
];