import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ICounterService } from 'app/shared/services/ICounterService';
import { interval } from 'rxjs/observable/interval';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit, OnDestroy {

    @Input() option: any;
    @Input() service: ICounterService;
    timer: Subscription;
    counter = 0;

    constructor() { }

    ngOnInit() {
        this.load();
        this.timer = interval(15000).subscribe(() => {
            this.load();
        });
    }

    ngOnDestroy(): void {
        this.timer.unsubscribe();
    }

    load() {
        this.service.load(this.option)
            .subscribe(counter => {
                this.counter = counter;
            }, err => {
                console.log(err);
            })
    }
}
